import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {    
    backgroundColor: '#E4E4E4',
    position: "relative"    
  },
  mainInfo: {
    fontSize: 25
  },
  imgHolder: {        
    width: 110,    
    height: 'auto',
    position: "absolute",
    top: 10,
    right: 10,
    border:"1px solid orange",
    borderRadius: "100%"
  },  
  subContent: {
    marginTop: 10,
    fontSize: 14,    
  },
  infoSec: {    
    marginBottom: 10
  },
  subHeading: {
    fontSize: 20,
    marginBottom: 5
  },
  descText: {    
    color: "#111",
    fontSize: 12,
    marginTop: 5,
    marginLeft: 5
  },  
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  hr: {
    height: 1,
    width: "100%",
    backgroundColor: "#333",
    marginBottom: 5
  }
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>

        {/* <View style={styles.imgHolder}>
          <Image src={myImg} style={styles.myImg}/>
        </View> */}

        <View style={styles.mainInfo}>
          <Text>Amirsaman Gordjahanbeygloo</Text>
          <Text>Javascript Developer</Text>
          <Text>Los Angeles, CA</Text>
        </View>

        <View style={styles.subContent}>

          <Text style={styles.subHeading}>Skills</Text>

          <View style={styles.hr}></View>

          <View style={styles.infoSec}>
            <Text>Languages :</Text>
            <Text style={styles.descText}>JavaScript ( ES6 / ECMAScript - NodeJS ), PHP, Html, Css / SCSS</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Frameworks & Libraries :</Text>
            <Text style={styles.descText}> React Js / NEXT Js ( Redux , Router , thunk , React-Native ), Vue ( Vue Router, Vuex ) , Express js , Jest ( JavaScript Testing Framework ) , Mongoose , jQuery ,
              Bootstrap, CodeIgniter </Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Technologies :</Text>
            <Text style={styles.descText}>Git , NPM , Webpack , Gulp , Babel , JSON , AJAX , Redux Thunk , Axios , PUG , Jade , JSX , svg , canvas</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Methodologies :</Text>
            <Text style={styles.descText}>MVC , BEM , Scrum Agile</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Task Management & Issue tracking :</Text>
            <Text style={styles.descText}>Trello , Jira</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Other :</Text>
            <Text style={styles.descText}>debugging techniques with tools such as Chrome or Safari Dev Tools , SEO and Performance optimisation / GTmetrix testing / google chrome audit performance testing</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Graphic Editors & Design Tools :</Text>
            <Text style={styles.descText}>Sketch , Adobe XD , Adobe Photoshop</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Code Editors and IDEs :</Text>
            <Text style={styles.descText}>Visual Studio Code , Atom , Xcode</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>CMS :</Text>
            <Text style={styles.descText}>WordPress, Keystone</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>OS :</Text>
            <Text style={styles.descText}>MAC, Windows</Text>
          </View>
                                                                      
        </View>

        <View style={styles.subContent}>
                  
          <Text style={styles.subHeading}>Work Experience</Text>

          <View style={styles.hr}></View>          

          <View style={styles.infoSec}>
            <Text>Sr Software Engineer at Guild Mortgage</Text>
            <Text style={styles.descText}>&#8226; August 2021 </Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Sr Software Engineer at insight global / Guild Mortgage</Text>
            <Text style={styles.descText}>&#8226; August 2021 - April 2022</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Front-End Developer at SHUUP agency</Text>            
            <Text style={styles.descText}>&#8226; July 2021 - August 2021</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Front-End Developer at theTUNDRA inc</Text>            
            <Text style={styles.descText}>&#8226; Oct 2019 - April 2021</Text>
          </View>

          <View style={styles.infoSec}>
            <Text style={styles.descText}></Text>
            <Text style={styles.descText}></Text>
            <Text style={styles.descText}></Text> 
            <Text style={styles.descText}></Text> 
            <Text style={styles.descText}></Text> 
            <Text style={styles.descText}></Text> 
            <Text style={styles.descText}></Text>            
          </View>

          <View style={styles.infoSec}>
            <Text>Front-End Developer at ERNYKA IT Innovation Group</Text>                        
            <Text style={styles.descText}>&#8226; Feb 2018 - Oct 2019</Text>            
          </View>

          <View style={styles.infoSec}>
            <Text>Front-End Developer at shemiranweb</Text>            
            <Text style={styles.descText}>&#8226; Apr 2017 - Feb 2018</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Freelance Front-End Developer</Text>            
            <Text style={styles.descText}>&#8226; November 2017 - Oct 2019</Text>
          </View>

        </View>        

        <View style={styles.subContent}>          
          <Text style={styles.subHeading}>Education</Text>

          <View style={styles.hr}></View>

          <View style={styles.infoSec}>
            <Text>Bachelor's Degree in Dramatic Arts</Text>
            {/* <Text style={styles.descText}>Academy of Arts and Architecture - Tehran, IR</Text> */}
          </View>

          <View style={styles.infoSec}>
            <Text>Web Development :</Text>
            <Text style={styles.descText}>React - The Complete Guide (incl Hooks, React Router, Redux), from Academind by Maximilian Schwarzmüller</Text>
            <Text style={styles.descText}>The Complete JavaScript Course 2019: Build Real Projects! by Jonas Schmedtmann</Text>
            <Text style={styles.descText}>Complete Wordpress Theme Development Course, by Rob Dey</Text>            
          </View>

          <View style={styles.infoSec}>
            {/* <Text>In Progress : </Text> */}
            <Text style={styles.descText}>Learn Node by Wes Bos</Text>
          </View>

          <View style={styles.infoSec}>            
            <Text style={styles.descText}>The Complete Node.js Developer Course (3rd Edition)</Text>
          </View>

          <View style={styles.infoSec}>
            <Text>Teamtreehouse Member :</Text>
            <Text style={styles.descText}>been a member of teamtreehouse.com since September 18, 2017</Text>
          </View>
          
        </View>

        <View style={styles.subContent}>
          <Text style={styles.subHeading}>Contact Info : </Text>
          <Text style={styles.descText}>Email : amirsaman.gord@gmail.com</Text>
          <Text style={styles.descText}>Website : samgord.me</Text>
          <Text style={styles.descText}>Github : github.com/simonestic</Text>
          <Text style={styles.descText}>Codepen : codepen.io/simonestic</Text>
          <Text style={styles.descText}>Linkedin : linkedin.com/in/amirsaman-gord</Text>            
        </View>                
      </View>      
    </Page>
  </Document>
);

export default MyDocument;