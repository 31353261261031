import React, { useEffect, useRef } from "react";

const Canvas = () => {
  const canvasRef = useRef(null);
  const maxIterations = 100;
  const zoomFactor = 1.001;
  let zoom = 1;
  let startTime = null;

  const render = (canvas, context, timestamp) => {
    if (!startTime) startTime = timestamp;
    const elapsedSeconds = (timestamp - startTime) / 1000;

    if (elapsedSeconds >= 20) return; // Stop animation after 20 seconds

    const width = canvas.width;
    const height = canvas.height;
    const imageData = context.getImageData(0, 0, width, height);
    const data = imageData.data;
    const dataLength = data.length;

    for (let i = 0; i < dataLength; i += 4) {
      const x = (i / 4) % width;
      const y = Math.floor(i / (4 * width));
      let a = map(x, 0, width, -2.5 / zoom, 1 / zoom);
      let b = map(y, 0, height, -1 / zoom, 1 / zoom);
      let ca = a;
      let cb = b;
      let n = 0;

      while (n < maxIterations) {
        const aa = a * a - b * b;
        const bb = 2 * a * b;
        a = aa + ca;
        b = bb + cb;

        if (a * a + b * b > 16) {
          break;
        }
        n++;
      }

      const brightness = map(n, 0, maxIterations, 0, 255);
      const hue = 260 * (brightness / 255);
      data[i] = brightness;
      data[i + 1] = brightness;
      data[i + 2] = brightness;
      data[i + 3] = 255;
    }

    context.putImageData(imageData, 0, 0);
    zoom *= zoomFactor;

    requestAnimationFrame((timestamp) => render(canvas, context, timestamp));
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    requestAnimationFrame((timestamp) => render(canvas, context, timestamp));

    return () => {
      cancelAnimationFrame(render);
    };
  }, []);

  const map = (value, start1, stop1, start2, stop2) => {
    return start2 + ((value - start1) / (stop1 - start1)) * (stop2 - start2);
  };

  return (
    <canvas
    style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        filter: "invert(1) grayscale(10) sepia(0) opacity(1)"
      }}
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};

export default Canvas;