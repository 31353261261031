import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import MyDocument from '../../components/MyDocument/MyDocument';

const PDF = () => (
  <PDFViewer>
    <MyDocument />
  </PDFViewer>
);

export default PDF;