import React, { Component, Suspense } from 'react';

import './Home.css';
import Cv from '../../components/Cv/Cv';
import Canvas from '../../components/Canvas/Canvas';
const Intro = React.lazy(() => import('../../components/Intro/Intro'));


class Home extends Component {

  constructor(props){    
    super();
    this.state = {
      expandCv : false,    
      showScrollIcon: true,
      rectBot: null,
      domEl: null,
      width: 0,
      height: 0
    }
  }

  componentDidMount() {    
    window.addEventListener('resize', this.updateWindowDimensions);
    setTimeout(() => {
      this.updateWindowDimensions();      
      let domEl = document.querySelector('#testRect');       
      this.setState({      
        domEl: domEl
      });
    }, 1);  
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  cvClickedHandler = () => {
    this.setState((beforeState)=>{      
      return {
        expandCv : !this.state.expandCv,
        showScrollIcon: beforeState.expandCv ? true : false,
      }
    })   
  }  

  CvScrollHandler = (e) => {
    var rect = this.state.domEl.getBoundingClientRect();
    this.setState({
      rectBot: rect.bottom
    });    
    
    if(this.state.rectBot >= 300 && this.state.expandCv === false){      
      this.setState({
        showScrollIcon: true,
      })
    }else{      
      this.setState({
        showScrollIcon: false,
      })
    } 
  }

  updateWindowDimensions = () => {    
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    if(this.state.height >= 1550){
      this.setState({
        showScrollIcon: false
      })
    }

  }
  
  render() {
    return (            
      <div className="Home" onClick={this.handleClick}> 
        <Canvas />
        <Suspense fallback={<div className="blob"></div>}>
          <Intro expandCv={this.state.expandCv} handleScroll={this.CvScrollHandler}/>        
        </Suspense>        
        {/* <Cv showScrollic={this.state.showScrollIcon} cvClicked={this.cvClickedHandler} expandCv={this.state.expandCv} handleScroll={this.CvScrollHandler}/> */}
      </div>      
    );
  }
}

export default Home;