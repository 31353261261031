import React, {Suspense} from 'react';
import './Cv.css';
import Scrollicon from '../UI/Scrollicon/Scrollicon';

class Cv extends React.Component {
  constructor(props){
    super();
    this.state = {
      teamtreehouseUsername: "samgord",
      badges: null,
      loading: false,      
    }
  }
  
  fetchBadges = async () => {        
    this.setState({
      loading: true,
    })
    const req = await fetch(`https://teamtreehouse.com/${this.state.teamtreehouseUsername}.json`);
    const data = await req.json();    
    this.setState({
      badges: data.badges,
      loading: false,
    });    
  }

  render(){
    return (
      <div className={this.props.expandCv ? ["CVpanel", "open"].join(' ') : "CVpanel"}>
        {
          this.props.showScrollic ? 
          (
            <div className="scrollIconHolder">
              {/* <Scrollicon /> */}
            </div>
          ) : 
          null
        }      
        <div onScroll={this.props.handleScroll} className={this.props.expandCv ? ["open", "overScroll"].join(' ') : ["overScroll"].join(' ')}>
          
          <div id="testRect" className="flex1">
            <h2>Skills</h2>
            <div className="divider"></div>
            <p><strong>Languages :</strong> JavaScript (ES6 / ECMAScript - NodeJS), PHP</p>
            <p><strong>Frameworks & Libraries :</strong> React js / NEXT js (Redux, Router, thunk, React-Native), Vue (Vue Router, Vuex), Express js, Jest ( JavaScript Testing Framework ), jQuery,
              Bootstrap, CodeIgniter
            </p>
            <p><strong>Technologies :</strong>Git, NPM, Webpack, Gulp, Babel, SCSS, PUG.js(Jade), JSX, HTML5, CSS3, SVG, html canvas</p>
            <p><strong>Task Management & Issue tracking :</strong> Trello , Jira</p>
            <p><strong>Other :</strong> debugging techniques with tools such as Chrome or Safari Dev Tools, SEO and Performance optimisation / GTmetrix testing / google chrome audit performance testing</p>
            <p><strong>Graphic Editors & Design Tools :</strong> Sketch, Adobe XD, Adobe Photoshop</p>
            {/* <p><strong>Code Editors and IDEs :</strong> Xcode, Visual Studio Code , Atom</p> */}
            <p><strong>CMS :</strong> WordPress</p>
            <p><strong>OS :</strong> MAC, Windows</p>
          </div>          
  
          <div className="biggerFlex">
            <h2>Teamtreehouse Member</h2>
            <div className="divider"></div>
            <div style={{"position": "relative"}}>                          
              <p>been a member of <a className="customLink" href="https://teamtreehouse.com" target="_balnk">Teamtreehouse</a> since September 18, 2017</p>
            </div>
          </div>
          
        </div>  
      </div>
    );
  }
}

export default Cv;