import React, { Component } from 'react';

// import Navigation from '../../components/Navigation/Navigation';

import './Layout.css';




class Layout extends Component {

  render(props) {    
    return (
      <div className="Layout">                   
        <div className="Layout__container">
          {/* <Navigation links={this.props.navigationLinks}></Navigation> */}
          {this.props.children}
        </div>        
      </div>
    );
  }
}

export default Layout;