import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './App.css';

// IMPORTING COMPONENTS
import Layout from './containers/Layout/Layout';
import Home from './containers/Home/Home';
import PDF from './containers/PDF/PDF';
import Portfolio from './containers/Portfolio/Portfolio';

class App extends Component {  

  render() {    
    
    let LINKS = null;
    let routes = null; 

    if(this.props.location.pathname === "/CV.PDF"){
      LINKS = []
    }else if(this.props.location.pathname !== "/"){
      LINKS = [            
        { label: 'Home', to: '/' , exact: true},        
      ]
    }else{
      LINKS = [                  
        { label: 'Portfolio', to: '/portfolio' , exact: true},
      ]
    }    

    routes = (
      <Switch>                
        <Route path="/" exact component={Home} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/CV.PDF" component={PDF} />   
      </Switch>
    );

    return (
      <div className="App">
        <Layout navigationLinks={LINKS}>                  
          {routes}
        </Layout>
      </div>
    );
  }
}

export default withRouter(App);
