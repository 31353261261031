import React, { Component } from 'react';
import './Portfolio.css';
import CodepenIcon from '../../components/UI/CodepenIcon';

class Portfolio extends Component {
  render() {                  
    return (      
      <div>
        <h1 className="headline">PORTFOLIO</h1>
        <section className="Portfolio">
          <a className={["Card", "CardTund"].join(' ')} href="https://thetundra.com/" target="_blank" rel="noopener noreferrer">
          </a>     
          <a className={["Card","CardTundVir"].join(' ')} href="https://thetundra.com/conventions/about-virtual-events" target="_blank" rel="noopener noreferrer">          
          </a>        
          <div className={["Card","CardEx"].join(' ')}>
          </div>
          <a className={["Card", "noOverlay"].join(' ')} href="https://codepen.io/simonestic" target="_blank" rel="noopener noreferrer">
            <div className="codepenHolder">
              <CodepenIcon />
              <span>My Pens on CodePen</span>
            </div>
          </a>
        </section>
      </div>
    );
  }
}

export default Portfolio;